.page_wrap {
  display: flex;
  justify-content: center;
}

.spin_wrap {
  display: flex;
  justify-content: center;
  height: 500px;
  align-items: center;
}