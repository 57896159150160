@import 'src/assets/scss/variables';

.checkbox-input {
  appearance: none;
  margin: 0;
  border: 1px solid #E5E5E5;
  //width: 24px;
  //height: 24px;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--gray-white);

  &:hover {
    //border: 1px solid var(--blue-900);
    border: 1px solid #E5E5E5;
    background-color: var(--blue-900);
    cursor: pointer;
  }

  & + label {
    cursor: pointer;
  }

  &:checked {
    background-color: var(--blue-900) !important;
    background-image: $checkbox-on-image;
    border: 1px solid #E5E5E5;
    background-repeat: no-repeat;
    background-position: 50%;
    //background-size: 16.8px;
    background-size: 1.05rem;
  }

}

.checkbox-label {
  cursor: pointer;
  display: inline-flex;

  [class=checkbox-span] {
    position: relative;
  }

  [class=checkbox-name] {
    padding-inline-start: 8px;
    padding-inline-end: 8px;
    font-size: 16px;
  }
}