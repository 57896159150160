@import '../../../../assets/scss/variables';

.checkbox-input {
    border-radius: 4px;
    appearance: none;
    margin: 0;
    border: 1px solid #e5e5e5;
    width: 1.5rem;
    height: 1.5rem;
    background-color: var(--gray-white);
    background-image: url('../../../../assets/Icons/svg/check_gray.svg');
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 1.05rem;

    &:hover {
        border: 1px solid #e5e5e5;
        background-color: var(--gray-100);
        cursor: pointer;
    }

    & + label {
        cursor: pointer;
    }

    // disabled style
    &:disabled {
        background-color: #fff;

        &:hover {
            cursor: not-allowed;
        }
    }

    &:checked {
        background-color: #ffffff;
        background-image: url('../../../../assets/Icons/svg/check.svg');
        border: 1px solid #e5e5e5;
    }
}

.checkbox-label {
    cursor: pointer;
    display: inline-flex;

    [class='checkbox-span'] {
        position: relative;
    }

    [class='checkbox-name'] {
        padding-inline-start: 8px;
        padding-inline-end: 8px;
        font-size: 16px;
    }
}
