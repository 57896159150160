.all_content_wrap {
  padding: 64px;

  .body2 {
    display: block;
    color: var(--gray-600, #7A7A7A);
  }
}

.info_register {

}

.content_area {
  margin-bottom: 100px;
}

.upload_area_wrap {
  padding: 36px 0 12px 0;
}

.green {
  color: var(--green-400, #00A886);
}

.register_area {

}

.sub_content {
  display: flex;

  ul {
    list-style: none;
  }

  ul li::before {
    content: "\2022";
    color: var(--secondary-g-400, #00A886);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  ul li {
    color: var(--gray-600, #7A7A7A);
    padding-bottom: 16px;

    /* Body/B2 */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    &:last-child {
      padding-bottom: 0;
    }
  }
}

.required_input_info {
  padding: 28px 0;
}

.exclamation_mark {
  display: block;
  width: 58px;
  height: 60px;

  background-image: url("../../../assets/Icons/svg/exclamation_mark.svg");
  background-repeat: no-repeat;
}


.ant-modal {
  [class='ant-modal-content'] {
    width: 418px;
    height: 100%;
    padding: 36px;
    margin: 0;
  }

  [class='ant-modal-title'] {
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  [class='ant-modal-body'] {
    overflow: hidden;
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    padding: 20px 0;
    color: #7a7a7a;
  }

  [class='ant-modal-footer'] {
    display: flex;
    justify-content: center;
  }
}
