.footer {
  min-width: 1290px;
  border-top: 1px solid var(--gray-300);
  background: var(--gray-50, #FBFBFB);
}

.inner {
  width: 1290px;
  margin: 0 auto;
  padding: 20px 0;

  display: flex;
  justify-content: space-between;
}

.info {
  color: var(--gray-600, #7A7A7A);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;

  p:last-child {
    margin-bottom: 0;
  }

  p:first-child {
    margin-top: 0;
  }
}

.logo {
  display: flex;
  align-items: center;
}