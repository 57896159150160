.alignCenter {
    display: flex;
    align-items: center;
}
.wrapper {
    background: #fff;
    padding: 22px 28px;
    min-height: 318px;

    + .title {
        margin-top: 18px;
    }
}
.title {
    margin: 0;
    background: #474747;
    border-radius: 4px 4px 0 0;
    color: #ffffff;
    font-size: 24px;
    padding: 16px 28px;
}
.cart_contents {
    padding-top: 74px;
}
.barChartTitle {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 20px 0;
    width: 50%;
    border-radius: 48px;
    background: #474747;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;

    &::after {
        content: '';
        position: absolute;
        top: 15px;
        right: 25px;
        width: 116px;
        height: 82px;
        background: url('/assets/Icons/svg/cloud.svg') no-repeat center/ contain;
    }
}

.barArea {
    padding: 8px;
    height: 42px;
    background: #00a886;
    position: relative;
    min-width: 48%;
    max-width: 95%;
    margin-top: 12px;

    &::after {
        content: '';
        position: absolute;
        top: -32%;
        right: -12px;
        background-image: url('/assets/images/statistic-icon1.png');
        width: 64px;
        height: 64px;
        background-size: 64px;
        background-repeat: no-repeat;
        background-position: right;
    }

    &Orange {
        background: #ff6f4b;
        min-width: 48%;
        max-width: 95%;
        &::after {
            background-image: url('/assets/images/statistic-icon2.png');
        }
    }
}

.relative {
    position: relative;
    padding-bottom: 10px;
    border-left: solid 1px #e5e5e5;
}

.progressArea {
    background: #fff;
}

.labelText {
    padding-left: 8px;
    color: #666666;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
}
.valueText {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: #fff;
    z-index: 9;
    left: 15px;
}
.subTitle {
    z-index: 9;
    border: 1px solid #000;
    border-radius: 36px;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    padding: 12px 20px;
}

.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}
