@import './variables';

/////////////////////////////////////////////////////////////////////////////////////////////
///             ant.d override
/////////////////////////////////////////////////////////////////////////////////////////////

.ant-layout {
    //min-height: 100vh;
    background: var(--gray-white, #fff);

    main {
        //min-height: calc(100vh - 180px);
    }
}

.ant-space.block {
    display: flex;
}

h1.ant-typography {
    text-align: center;
}

.hide {
    display: none;
}

// antd select focus 상태일 때
.ant-select-open:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--gray-white, #fff);

    .ant-select-selector {
        .ant-select-selection-item {
            color: var(--gray-900, #252525) !important;
        }
    }
}

// antd select focus 상태일 때 텍스트 색상
.ant-select-single.ant-select-open .ant-select-selection-item {
    color: var(--gray-900, #252525) !important;
}

// antd 계정관리 validation 성공 시 하단 margin 제거
.ant-form-item-has-success {
    margin-bottom: 0 !important;
}

// 교구재 2depth 메뉴 스타일링
.tools_overlay_menu {
    .ant-dropdown {
        padding: 8px;
    }

    .ant-dropdown-menu-title-content {
        font-size: 18px;
        text-align: center;
    }
}

// 로그인 관리자에게 문의 버튼 툴팁
.contact_admin_btn {
    .ant-tooltip-inner {
        text-align: center;
        padding: 6px 16px;
    }
}

//// datepicker
//.ant-picker {
//  margin-top: 9px;
//  height: 50px;
//  width: 179px;
//}

.ant-picker {
    &.report_date_picker {
        height: 100%;
        width: 100%;
    }
}

// datepicker 선택시 줄어드는 문제 해결 1
// .ant-picker-focused {
//     height: 100%;
//     &.report_date_picker {
//         width: 100%;
//     }
// }

// datepicker 선택시 줄어드는 문제 해결 2
// .ant-picker-status-success {
//     height: 100%;
//     width: 100%;
// }

/////////////////////////////////////////////////////////////////////////////////////////////
///             ant.d color
/////////////////////////////////////////////////////////////////////////////////////////////
.ant-segmented {
    background-color: #d9d9d9;
}

/////////////////////////////////////////////////////////////////////////////////////////////
///             common
/////////////////////////////////////////////////////////////////////////////////////////////
.logo {
    margin: 0 auto;
    background-image: url('../images/logo.svg');
    background-repeat: no-repeat;

    display: flex;
    width: 185px;
    height: 35px;
    padding: 6px 0;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
}

main section {
    margin: 32px 0 64px;
}

.btnWrap {
    display: flex;
    justify-content: center;
    margin: 16px auto;
}

.main_content {
    position: relative;
}

/////// root 변수 //////////
:root {
    --gutter: '16px';

    //////////// google font ///////////////////////
    --global-font-family: 'Noto Sans KR', sans-serif;

    /////////// Antd Color Palette /////////////////
    --blue-50: #e3f1f9;
    --blue-100: #badbf0;
    --blue-200: #8fc4e7;
    --blue-300: #67addd;
    --blue-400: #499dd8;
    --blue-500: #2d8dd2;
    --blue-600: #2480c5;
    --blue-700: #1a6fb4;
    --blue-800: #125fa2;
    --blue-900: #024383;

    --green-50: #dff3ee;
    --green-100: #afe0d3;
    --green-200: #7bccb7;
    --green-300: #43b89b;
    --green-400: #00a886;
    --green-500: #009873;
    --green-600: #008b67;
    --green-700: #007b58;
    --green-800: #006b4a;
    --green-900: #004f30;

    --red-50: #fbe9e8;
    --red-100: #ffccbe;
    --red-200: #ffab95;
    --red-300: #ff8a6b;
    --red-400: #ff6f4b;
    --red-500: #ff552d;
    --red-600: #f94f29;
    --red-700: #eb4824;
    --red-800: #dd4120;
    --red-900: #c33517;

    --yellow-50: #fffde6;
    --yellow-100: #fff9c3;
    --yellow-200: #fef59b;
    --yellow-300: #fdf072;
    --yellow-400: #fbec50;
    --yellow-500: #f9e62c;
    --yellow-600: #fcd82c;
    --yellow-700: #fac023;
    --yellow-800: #f9a71a;
    --yellow-900: #f67d08;

    --gray-50: #fbfbfb;
    --gray-100: #f7f7f7;
    --gray-200: #f1f1f1;
    --gray-300: #e5e5e5;
    --gray-400: #c2c2c2;
    --gray-500: #a4a4a4;
    --gray-600: #7a7a7a;
    --gray-700: #666666;
    --gray-800: #474747;
    --gray-900: #252525;
    --gray-white: #fff;
}

// 글로벌 폰트 적용
* {
    font-family: var(--global-font-family) !important;
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    padding: 0;
    margin: 0;
}

body {
    //width: 1920px;
}

a {
    color: var(--blue-900);

    &:hover {
        color: var(--blue-900);
    }
}

//// header
.h1 {
    //display: inline;
    color: var(--gray-800, #474747);
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: 88px; /* 110% */
    letter-spacing: -1px;
}

.h2 {
    //display: inline;
    color: var(--gray-800, #474747);
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px; /* 112.5% */
    letter-spacing: -1px;
}

.h3 {
    //display: inline;
    color: var(--gray-800, #474747);
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px; /* 116.667% */
    letter-spacing: -1px;
}

.h4 {
    //display: inline;
    color: var(--gray-800, #474747);
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px; /* 122.222% */
}

.h5 {
    //display: inline;
    color: var(--gray-800, #474747);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
}

.h6 {
    //display: inline;
    color: var(--gray-800, #474747);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 125% */
}

.h7 {
    //display: inline;
    color: var(--gray-800, #474747);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
}

.h8 {
    //display: inline;
    color: var(--gray-800, #474747);
    text-align: right;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
}

////// Body
.sub_title1 {
    color: var(--gray-900, #252525);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 175% */
}

.sub_title2 {
    color: var(--gray-900, #252525);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 175% */
}

.sub_title3 {
    color: var(--gray-900, #252525);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 185.714% */
}

.body1 {
    color: var(--gray-800, #474747);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
}

.body2 {
    color: var(--gray-800, #474747);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
}

.body3 {
    color: var(--gray-800, #474747);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 185.714% */
}

.caption1 {
    color: var(--gray-600, #7a7a7a);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 185.714% */
}

///// Slim
.slim_text1 {
    color: var(--gray-900, #252525);
    font-size: 52px;
    font-style: normal;
    font-weight: 300;
    line-height: 60px; /* 115.385% */
    letter-spacing: -1px;
}

.slim_text2 {
    color: var(--gray-900, #252525);
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: 42px; /* 116.667% */
}

.slim_text3 {
    color: var(--gray-900, #252525);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
}

.slim_text4 {
    color: var(--gray-900, #252525);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 144.444% */
}

// pagenation
.ant-pagination .ant-pagination-item {
    border: none;
    &:hover {
        color: var(--gray-900, #252525) !important;
    }
}

.ant-pagination .ant-pagination-item-active {
    border-color: transparent;
    &:hover {
        border-color: transparent;
    }
}

.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover {
    transition: all 0.2s;
    background-color: var(--gray-white, #fff);
}

// 버튼 reset style
.button-reset-type {
    background: none;
    border: none;
    cursor: pointer;

    margin: 0;
    padding: 0;
}

// AntD tooltip
.ant-tooltip {
    .ant-tooltip-arrow {
        bottom: 1px !important;
    }
    // .ant-tooltip-content > .ant-tooltip-inner
    .ant-tooltip-inner {
        padding: 10px 24px 10px 24px;
        width: max-content;
        > div {
            width: max-content;
        }
    }

    &.type-line {
        .ant-tooltip-inner,
        .ant-tooltip-arrow::after {
            background-color: white;
            border: 1px solid var(--blue-900);
            color: var(--blue-900);
        }

        .ant-tooltip-inner {
            text-align: center;
            font-size: 20px;
            font-weight: 700;
            line-height: 1;
            padding: {
                left: 15px;
                right: 15px;
            }
        }
    }

    &.active {
        .ant-tooltip-inner,
        .ant-tooltip-arrow::after {
            background-color: var(--blue-900);
        }

        .ant-tooltip-inner {
            color: white;
        }
    }
}

//helper
.text-white {
    color: white !important;
}

button {
    background: none;
    border: none;
    cursor: pointer;

    margin: 0;
    padding: 0;
}
