.container {
    height: 473px;

    .page_header_section {
        width: 1290px;
        margin: 0 auto;
        padding-top: 150px;

        .page_header {
            margin-bottom: 48px;

            .title {
                padding-bottom: 50px;
                position: relative;

                [class='slim_text1'] {
                    color: var(--gray-white, #fff) !important;
                    margin-bottom: 50px;
                }

                .icon {
                    position: relative;
                    right: 49px;
                    top: -174px;

                    svg {
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                }
            }
        }
    }

    .content {
        background: var(--gray-white, #fff);
        min-height: 100vh;

        border-radius: 4px 4px 0 0;
        width: 1290px;
        margin: 0 auto;
        top: -85px;
        position: relative;
    }
}

.content {
    background: var(--gray-white, #fff);
    min-height: 100vh;

    border-radius: 4px 4px 0 0;
    width: 1290px;
    margin: -85px auto;
    margin-bottom: 200px;
    position: relative;
}

// 배너 레이아웃 컬러 분기처리
.program {
    background: var(--primary-400, #499dd8);
}

.tutors {
    background: var(--green-400, #00a886);
}

.tools {
    //background: var(--yellow-900, #f67d08);
    background: var(--blue-600, #2480C5);
}

.report {
    background: var(--yellow-800, #f9a71a);
}

.statistics {
    background: var(--red-300, #ff8a6b);
}

.survey {
    background: var(--green-300, #67addd);
}

.accounts {
    background: var(--gray-700, #666666);
}

//문의하기 페이지 추가
.contact{
    background: var(--blue-900, #024383);
}
