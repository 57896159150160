.ant-alert {
  padding: 16px;
  width: 354px;
  height: 64px;

  position: fixed;
  top: -100px; /* 초기에 화면 위에 숨겨둠 */
  left: 50%;
  transform: translateX(-50%);
  transition: top 0.5s;

  &.show {
    top: 20px;
  }


  [id=alert_icon] {
    margin-right: 16px;
  }

  [id=btn_close_icon] {
    &:hover {
      path {
        fill: var(--gray-600);
      }
    }
  }
}
