.not_found_page_wrap {
  height: 500px;
  align-items: center;

  min-height: 500px;

  .not_found_page_top {
    padding-top: 124px;
    padding-bottom: 152px;

    .sub_title1 {
      font-weight: 700;
    }

    .not_found_page_icon {
      display: flex;
      justify-content: center;
      margin-bottom: 36px;

      &:before {
        background-image: url(../../../assets/Icons/svg/Re.svg);
        background-repeat: no-repeat;
        width: 114px;
        height: 96px;
        content: "";
        display: block;
      }
    }

    .not_found_page_title {
      display: flex;
      justify-content: center;
    }
  }

  .not_found_page_bottom {
    display: flex;
    justify-content: center;

    button {
      margin-right: 10px !important;
    }
  }
}