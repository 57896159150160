.wrap {
  background: var(--gray-white);
  border-radius: 4px;
  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.05),
  0px 12px 16px -4px rgba(16, 24, 40, 0.1);
  margin-bottom: 48px;
}

.count_wrap {
  display: flex;
  padding: 34px 40px;
  justify-content: space-around;

  .item {
    display: flex;
    align-items: center;
    height: 60px;
    position: relative;

    &:after {
      content: '';
      display: block;
      margin-top: 20px;
      margin-left: 2px;
      margin-right: 14px;
      width: 1px;
      height: 64px;
      background: var(--gray-300, #e5e5e5);
      opacity: 0.48;
      position: absolute;
      right: -70px;
      top: -22px;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    .icon {
      padding-right: 20px;
    }

    &:first-child {
      padding-left: 0;
    }

    .green {
      color: var(--green-400, #00a886);
      padding-left: 8px;
    }

    .yellow {
      color: var(--yellow-800, #f9a71a);
      padding-left: 8px;
    }

    .blue {
      color: var(--blue-500, #2d8dd2);
      padding-left: 8px;
    }

    .gray {
      color: var(--gray-400, #c2c2c2);
      padding-left: 8px;
    }
  }
}

.tools {
  grid-template-columns: 3fr 1fr;
}

.content_wrap {
  display: grid;
  gap: 24px;

  flex-shrink: 0;
  background: var(--gray-50, #fbfbfb);
  padding: 40px 32px;
  border-radius: 0 0 4px 4px;

  position: relative;

  .item_wrap {
    display: flex;
    align-content: center;
    row-gap: 24px;
    flex-wrap: wrap;

    &.tutor {
      width: 1000px
    }

    [class='ant-picker-dropdown'] {
      min-width: 210px;
    }

    [class='ant-picker'] {
      border-radius: 4px;
      border: 1px solid var(--gray-300, #e5e5e5);
      background: var(--gray-white, #fff);
    }

    .ant-picker-input > input::placeholder {
      font-size: 18px;
    }

    [class='ant-picker-input-placeholder'] {
      input {
        color: var(--gray-300, #e5e5e5);

        /* Body/B1 */
        font-family: Noto Sans KR;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 166.667% */
        &::placeholder {
          font-size: 18px;
        }
      }
    }

    [class='ant-picker-input'] {
      input {
        color: var(--gray-300, #e5e5e5);

        /* Body/B1 */
        font-family: Noto Sans KR;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 166.667% */
        &::placeholder {
          font-size: 18px;
        }
      }
    }

    [class='ant-picker-focused'] {
      color: var(--gray-900, #252525) !important;
    }

    .tilde {
      width: 20px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0 16px;
    }

    .btn_wrap {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      padding-right: 36px;
      row-gap: 12px;

      button {
        font-size: 16px;
        font-weight: 400 !important;
      }
    }

    .title {
      min-width: 130px;
      display: flex;
      align-items: center;

      .expand {
      }
    }

    .extra_btn_wrap {
      position: absolute;
      right: 36px;
      bottom: 34px;
      display: flex;
      gap: 5px;

      .reset {
        padding: 8px;
      }

      .expand {
        padding: 8px 8px;
      }
    }
  }
}

.padding_left {
  padding-left: 24px;
}

.h100 {
  height: 100%;
}