.header {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;
    line-height: 30px;
    .sm {
        font-size: 24px;
    }

    .lg {
        font-size: 32px;
    }
}

.container {
    margin-bottom: 16px;
    div {
        display: 'flex';
        gap: 8px;
        align-items: 'center';
    }
}
