.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  .sort {
    display: flex;
    align-items: center;

    .sub_title2 {
      color: var(--gray-400, #C2C2C2);
    }

    .item {
      /* Subtitle/T2 */
      font-family: Noto Sans KR;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 175% */

      padding-right: 16px;

      &.active {
        color: var(--gray-900, #252525);
        font-weight: 700;
      }
    }
  }

  .search {
    display: flex;
    align-items: center;

    .search_program {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .area {
      padding: 8px;
      margin-right: 28px;
    }

    .grid {
      padding: 8px;
      margin-right: 12px;
    }

    .list {
      padding: 8px;
    }

    .text {
      padding-right: 8px;
      min-width: 319px;
    }

    .active {
      rect {
        stroke: #252525;
      }

      path {
        stroke: #252525;
      }
    }

    [class=ant-input] {
      width: 319px !important;

      [class=ant-input-suffix] {
        cursor: pointer;
      }
    }
  }
}

.ant_input {
  font-size: 16px;
  padding: 12px;

  &:disabled {
    border: none;
    background: #fff !important;
    color: #7a7a7a;
  }
  [class='ant-input-suffix'] {
    cursor: pointer;
  }

  [id='btn_close_icon'] {
    &:hover {
      path {
        fill: var(--gray-600);
      }
    }
  }
}