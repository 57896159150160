.area {
    [class='ant-picker-dropdown'] {
        min-width: 210px;
    }

    [class='ant-picker'] {
        border-radius: 4px;
        border: 1px solid var(--gray-300, #e5e5e5);
        background: var(--gray-white, #fff);
    }

    .ant-picker-input > input::placeholder {
        font-size: 16px;
    }

    [class='ant-picker-input-placeholder'] {
        input {
            color: var(--gray-700, #7a7a7a);

            /* Body/B1 */
            font-family: Noto Sans KR;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            &::placeholder {
                font-size: 16px;
            }
            &:hover {
                font-size: 16px;
            }
        }
    }

    [class='ant-picker-input'] {
        input {
            color: var(--gray-700, #7a7a7a);

            /* Body/B1 */
            font-family: Noto Sans KR;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 166.667% */
            &::placeholder {
                font-size: 16px;
            }

            &:hover {
                font-size: 16px;
            }
        }
    }

    [class='ant-picker-focused'] {
        color: var(--gray-900, #252525) !important;
    }
}

.datepicker {
    margin-top: 9px;
    height: 50px;
    width: 179px;
    &.no_mt {
        margin-top: 0 !important;
    }
}

.w100 {
    width: 100%;
}
