.layout {
    width: 100%;
    height: 84px;
    flex-shrink: 0;
    border-bottom: 1px solid var(--gray-300, #e5e5e5);
    border-top: 3px solid var(--gray-900, #252525);

    [class='ant-select-selection-item'] {
        padding-left: 6px !important;
        color: var(--gray-white, #fff);
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 140% */
    }

    [class='ant-select-arrow'] {
        left: 180px !important;
        top: 38px;
    }

    .second {
        position: relative;

        color: var(--gray-white);
        border: none !important;
        width: 233px !important;
        height: 80px !important;

        font-family: Noto Sans KR;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 140% */

        .item {
            padding: 26px 20px;

            &:after {
                content: '';
                display: block;
                margin-top: 20px;
                margin-left: 2px;
                margin-right: 14px;
                width: 1px;
                height: 38px;
                background: var(--gray-white, #fff);
                opacity: 0.48;
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }

    // breadcrumb와 select 컬러 분기처리
    &.program {
        background: var(--primary-400, #499dd8);

        [class='ant-select-selector'] {
            background: var(--primary-400, #499dd8);
            padding: 0;

            &::before {
                content: '';
                display: block;
                margin-top: 20px;
                margin-left: 2px;
                margin-right: 14px;
                width: 1px;
                height: 38px;
                background: var(--gray-white, #fff);
                opacity: 0.48;
            }
        }
    }

    &.tutors {
        background: var(--green-400, #00a886);

        [class='ant-select-selector'] {
            background: var(--green-400, #00a886);
            padding: 0;

            &:before {
                content: '';
                display: block;
                float: left;
                margin-top: 20px;
                margin-left: 2px;
                margin-right: 14px;
                width: 1px;
                height: 38px;
                background: var(--gray-white, #fff);
                opacity: 0.48;
            }
        }
    }

    &.tools {
        //background: var(--yellow-900, #f67d08);
        background: var(--blue-600, #2480C5);

        [class='ant-select-selector'] {
            //background: var(--yellow-900, #f67d08);
            background: var(--blue-600, #2480C5);
            padding: 0;

            &:before {
                content: '';
                display: block;
                float: left;
                margin-top: 20px;
                margin-left: 2px;
                margin-right: 14px;
                width: 1px;
                height: 38px;
                background: var(--gray-white, #fff);
                opacity: 0.48;
            }
        }
    }

    &.report {
        background: var(--yellow-800, #f9a71a);

        [class='ant-select-selector'] {
            background: var(--yellow-800, #f9a71a);
            padding: 0;

            &:before {
                content: '';
                display: block;
                float: left;
                margin-top: 20px;
                margin-left: 2px;
                margin-right: 14px;
                width: 1px;
                height: 38px;
                background: var(--gray-white, #fff);
                opacity: 0.48;
            }
        }
    }

    &.statistics {
        background: var(--red-300, #ff8a6b);

        [class='ant-select-selector'] {
            background: var(--red-300, #ff8a6b);
            padding: 0;

            &:before {
                content: '';
                display: block;
                float: left;
                margin-top: 20px;
                margin-left: 2px;
                margin-right: 14px;
                width: 1px;
                height: 38px;
                background: var(--gray-white, #fff);
                opacity: 0.48;
            }
        }
    }

    &.survey {
        background: var(--green-300, #67addd);

        [class='ant-select-selector'] {
            background: var(--green-300, #67addd);
            padding: 0;

            &:before {
                content: '';
                display: block;
                float: left;
                margin-top: 20px;
                margin-left: 2px;
                margin-right: 14px;
                width: 1px;
                height: 38px;
                background: var(--gray-white, #fff);
                opacity: 0.48;
            }
        }
    }

    &.accounts {
        background: var(--gray-700, #666666);

        [class='ant-select-selector'] {
            background: var(--gray-700, #666666);
            padding: 0;

            &:before {
                content: '';
                display: block;
                float: left;
                margin-top: 20px;
                margin-left: 2px;
                margin-right: 14px;
                width: 1px;
                height: 38px;
                background: var(--gray-white, #fff);
                opacity: 0.48;
            }
        }
    }

    &.contact {
        background: var(--blue-900, #024383);

        [class='ant-select-selector'] {
            background: var(--blue-900, #024383);
            padding: 0;

            &:before {
                content: '';
                display: block;
                float: left;
                margin-top: 20px;
                margin-left: 2px;
                margin-right: 14px;
                width: 1px;
                height: 38px;
                background: var(--gray-white, #fff);
                opacity: 0.48;
            }
        }
    }

    svg {
        margin-top: 1px;
    }

    [class='breadcrumb_wrap'] {
        display: flex;

        [class='select_ant-select-selector'] {
            width: 233px !important;
        }

        [class='home_btn'] {
            display: inline-flex;
            align-items: center;
            padding: 16px;

            background: none;
            border: none;
            cursor: pointer;
        }
    }

    // selcet 컴포넌트
    [class='ant-select-selector'] {
        background: var(--primary-400, #499dd8);
        color: var(--gray-white);
        border: none !important;

        width: 230px !important;
        height: 80px !important;
        border-radius: 0 !important;

        &:hover {
            //border-color: var(--blue-900) !important;
            //      //background: var(--gray-white, #fff) !important;
        }
    }

    [class='ant-select-arrow'] {
        path {
            stroke: var(--gray-white);
        }
    }
}
