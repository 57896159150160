.login {
  background: var(--gray-50, #FBFBFB);
  position: relative;
  background-image: url("../../assets/Icons/svg/login_back.svg");
  background-repeat: no-repeat;
  background-position: center 63px;
  padding-bottom: 100px;

  .header {
    display: flex;
    justify-content: center;

    margin: 0 auto;
    padding-top: 120px;

    .logo_wrap {
      display: inline-flex;
      align-items: center;
      gap: 20px;

      .logo {
        margin-right: 24px;
      }

      span {
        color: var(--gray-900, #252525);

        /* Header/H5 */
        font-family: Noto Sans KR;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 125% */
      }

    }
  }

  .container {
    position: relative;
    z-index: 1;
    margin: 52px auto 80px;
    height: 600px;
    width: 1040px;
  }

  .box {
    display: flex;
    border-radius: 4px;
    border: 1px solid var(--gray-300);
    background: var(--gray-white, #fff);
    box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.05), 0px 12px 16px -4px rgba(16, 24, 40, 0.1);

    .image {
      width: 520px;
      height: 600px;
      background: var(--secondary-g-400, #00a886);
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      &.dev {
        background: var(--gray-400, #c2c2c2);
      }
    }

    .form {
      width: 520px;
      height: 600px;
      flex-shrink: 0;

      .login_area {
        padding: 48px;
        height: 100%;

        .desc {
          margin-top: 16px;
        }
      }
    }
  }
}


.logo {
  background-image: url(http://localhost:3000/static/media/logo.94139cd….svg);
  background-repeat: no-repeat;
  width: 185px;
  height: 20px;
}

.admin {
  position: absolute;
  bottom: 10px;
  left: 65%;

  .btn {
    padding: 8px;
    align-items: center;
    gap: 8px;
    margin: 0 auto;
    width: 230px;

    span {
      color: var(--gray-900, #252525);
    }
  }
}


.ant-input {
  padding: 17px 22px !important;
}

.id_input {
  margin-top: 35px;
}

.password_input {
  margin-top: 15px;
}

.login_btn {
  margin-top: 25px;
}

.validation {
  display: inline-grid;
  margin-top: 15px;

  .error {
    color: var(--secondary-r-600, #f94f29);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }
}


.login_area_title {
  color: var(--gray-900, #252525);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
}
