.survey-wrapper {
    margin: 0 auto;
    max-width: 650px;
    display: grid;
    row-gap: 1rem;

    h1 {
        text-align: center;
    }
}

.grid-wrap {
    display: grid;
    grid-template-columns: 1fr 2fr;
    row-gap: 5px;
}

.question-creator {
    background: gray;
    padding: 10px;
    border-radius: 0.5rem;
}

.grid-container {
    padding: 1rem;
    border: 1px solid lightgray;
    border-radius: 0.5rem;
    background: #fff;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
}

.grid-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
}

.question-box {
    position: relative;
    border-radius: 0.5rem;
    padding: 0.6rem 1rem;
    background: #fff;
    border: 1px solid lightgray;

    label {
        size: 0.8rem;
        font-weight: 500;
        color: #000;
    }
}

.survey-intro {
    > :first-child {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            margin: 0;
            margin-bottom: 1rem;
            padding: 0;
        }
        position: relative;
        background-size: cover;
        border-bottom: 0.5px solid lightgray;
    }
    img {
        max-width: 550px;
        object-fit: cover;
    }
    max-width: 750px;
    background-color: white;
    border-radius: 0.5rem;
    padding: 3rem;
    margin: 0 auto;
    .sd-body.sd-body--static {
        margin: 0;
    }
    .sd-body.sd-body--responsive {
        padding: 0;
    }
    .sd-page {
        padding: 0;
        justify-items: center;
    }

    .sd-action-bar {
        justify-content: center;
    }
}
