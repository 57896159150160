.container {
  background: var(--gray-50);
  height: 1000px;

  .banner {
    background: var(--primary-900, #024383);
    height: 126px;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 1290px;
      margin: 0 auto;
      background: url("../../assets/Icons/svg/dashboard.svg") no-repeat 90%;
      height: 126px;

    }

    svg {
      position: absolute;
      right: 0;
    }

  }

  .card {
    width: 1290px;
    min-height: 792px;
    flex-shrink: 0;

    border-radius: 4px;
    border: 1px solid var(--gray-300, #E5E5E5);
    background: var(--gray-white, #FFF);

    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    margin: 36px auto;
    display: flex;
  }

  .wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: -webkit-fill-available;
  }
}

