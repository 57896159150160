.container {
    padding: 62px 64px 48px 64px;

    [class='h5'] {
        margin-bottom: 24px;
    }

    [class='ant-picker'] {
        height: 100%;
        width: 100%;

        [class='ant-picker-focused'] {
            border-color: #024383;
            box-shadow: none;
            outline: 0;
            background-color: transparent;
            width: 100% !important;
        }
    }
}

.card {
    height: 100%;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
}

.section {
    &_lg {
        margin-bottom: 80px;
    }

    &_sm {
        margin-bottom: 26px;
    }

    &_24 {
        margin-bottom: 24px;
    }

    &_8 {
        margin-bottom: 8px;
    }

    &_md {
        margin-bottom: 48px;
    }

    &_table {
        padding: 10px 25px;
    }

    &_total {
        padding: 28px 36px;
    }
}

.main_content {
    height: 100%;
    display: inline-flex;
    align-items: center;
}

.label {
    padding: 20px 40px;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
}

.padding {
    &_34 {
        padding: 34px 0;
    }

    &_35 {
        padding: 35px 46px;
    }

    &_10 {
        padding: 10px 0;
    }

    &_12 {
        padding: 12px 0;
    }

    &_24 {
        padding: 24px;
    }

    &_2436 {
        padding: 24px 36px;
    }

    &_2036 {
        padding: 20px 36px;
    }

    &_8 {
        padding: 11px 8px;
    }

    &_1010 {
        padding: 10px 10px;
    }

    &_0028 {
        padding: 0 28px;
    }
}

.value {
    padding: 24px 0;

    &.project_name {
        font-size: 16px;
        color: var(--gray-600, #7b7b7b);
    }

    span {
        font-size: 16px;
        color: var(--gray-600, #7b7b7b);
    }
}

.text {
    font-size: 16px;
    color: var(--gray-600, #7b7b7b);
}

.head {
    background: var(--gray-50, #fbfbfb);
    text-align: center;
    padding: 16px 0;
}

.bottom_line {
    border-bottom: 1px solid var(--gray-300, #e5e5e5);
}

.top_line {
    border-top: 1px solid var(--gray-300, #e5e5e5);
}

.right_line {
    border-right: 1px solid var(--gray-300, #e5e5e5);
}

.box_line {
    border: 1px solid var(--gray-300, #e5e5e5);
}

.right {
    text-align: right;

    [class='ant-input-number-input-wrap'] {
        input {
            text-align: right;
        }
    }
}
.left {
    text-align: left;
}
.center {
    text-align: center;
}

.red {
    color: var(--red-800, #dd4120) !important;
}

.blue {
    background: var(--blue-500, #2d8dd2);
}

.text_white {
    color: var(--gray-white, #fff) !important;
}

.center {
    text-align: center;
}

.gray {
    color: var(--gray-600, #7b7b7b);
}

.align_center {
    display: flex;
    align-items: center;
}

.height {
    &_100 {
        height: 100% !important;
    }
}

.margin {
    &_r36 {
        margin-right: 36px;
    }

    &_b16 {
        margin-bottom: 14px;
    }

    &_b10 {
        margin-bottom: 10px;
    }
}

.label_section {
    display: inline-flex;
    justify-content: end;
    align-items: center;
    padding-right: 10px;
}

.width_100 {
    width: 100%;
}

.ellipsis {
    white-space: nowrap; /* 줄 바꿈 금지 */
    overflow: hidden; /* 내용이 넘칠 경우 숨김 처리 */
    text-overflow: ellipsis; /* 초과 시 "..."으로 표시 */
    max-width: 150px; /* 최대 너비 지정 (원하는 값으로 조정) */
}

.align_center_inline {
    display: inline-flex;
    align-items: center;
    justify-content: end;
}

.ant_input {
    font-size: 16px;
    padding: 12px;

    input {
        padding: 0 !important;
    }
}

.not_found_container {
    padding: 20px;
}

.not_found_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    //margin-bottom: 36px;

    &:before {
        background-size: 65px;
        background-image: url(../../assets/Icons/svg/Re.svg);
        background-repeat: no-repeat;
        //background-size: 80px;
        width: 81px;
        height: 68px;
        content: '';
        display: block;
    }
}

.not_found_title {
    display: flex;
    justify-content: center;
}

.delete_icon {
    background-image: url(../../assets/Icons/svg/trash.svg);
    background-repeat: no-repeat;
    width: 18px;
    height: 20px;
    content: '';
    display: block;
}

.delete {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.flex_center {
    display: inline-flex;
    justify-content: center;
}

.w80 {
    width: 80px;
}
.w160 {
    width: 160px;
}

.w127 {
    width: 127px;
}

.w150 {
    width: 150px;
}

.h176 {
    height: 100px;
}

.h190 {
    height: 190px;
}

.h245 {
    height: 245px;
}

.total {
    background-color: var(--gray-white, #fff);
    border: none;
    color: var(--red-800, #dd4120);

    input {
        font-family: Noto Sans KR;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 700;
        line-height: 30px; /* 166.667% */

        padding: 0 !important;
    }

    &:hover {
        background-color: transparent;
    }
}
.mt40 {
    margin-top: 40px;
}
.mb0 {
    margin-bottom: 0 !important;
}

.space_between {
    justify-content: space-between;
}

.plus {
    border-radius: 0 0 4px 4px;
    border: 1px solid var(--gray-300, #e5e5e5);
    background: var(--gray-100, #f6f6f6);
}

.no_shadow {
    box-shadow: none;
}

.no_number_handler {
    [class='ant-input-number-handler-wrap'] {
        display: none;
    }
}

.red {
    color: red;
}

/* 240627 월간보고 수정 */
.radius_4 {
    border-radius: 4px;
}
.mg0 {
    margin: 0;
}
.shrink_0 {
    flex-shrink: 0;
}

.transparent_bg {
    background-color: transparent;
}
.box_outer {
    margin-top: 24px;
}
.basic_box {
    //border: 1px solid var(--gray-300, #e5e5e5);
    border-radius: 4px;
    display: flex;
    align-items: center;
    textarea {
        //height: 85px;
        height: 90px;
    }
    .grow_1 {
        flex-grow: 1;
    }
}
.basic_item {
    margin-left: 28px;
    p {
        margin: 0;
        margin-bottom: 9px;
    }
    & + .basic_item {
        margin-left: 16px;
    }
}

.count_box {
    width: 50%;
    display: flex;
    flex-direction: column;
    p {
        text-align: center;
        margin: 0;
        & + textarea {
            width: 100%;
        }
    }
    .left {
        text-align: left;
    }
    .ant_input {
        margin-top: 14px;
    }
    textarea {
        margin-top: 14px;
        min-height: 120px;
    }
}

.graybox {
    background-color: var(--gray-50, #fbfbfb);
}
.gray_sub_box {
    padding: 10px 16px;
}

.textarea_xmin {
    //min-height: 48px !important;
    height: 48px;
}
.textarea_min {
    min-height: 90px !important;
}
.textarea_lg {
    min-height: 120px !important;
}
.textarea_xlg {
    min-height: 182px !important;
}

.no_top_border {
    border-top: none;
}

.no_right_border {
    border-right: none;
}

.no_border_radius {
    border-radius: 0;
}

.top_border_radius {
    border-radius: 0 0 4px 4px;
}

.bottom_border_radius {
    border-radius: 4px 4px 0 0;
}

.no_top_left_border_radius {
    border-radius: 0 4px 0 0;
}

.no_top_right_border_radius {
    border-radius: 4px 0 0 0;
}
