
.row {
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;
}

.mb0 {
  margin-bottom: 0 !important;
}

.text_row {
  display: grid;
  grid-template-columns: 1fr 0fr 1fr auto;
  gap: 22px;
  align-items: center;
  padding: 12px 12px;
  border-bottom: 1px solid #f0f0f0;
}

.col {
  text-align: center;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
}

.btn_add {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  color: #fff;
  padding: 8px 16px;
  border: 1px solid #E5E5E5;
  cursor: pointer;
  font-size: 16px;
}


.padding {
  &_top {
    padding-top: 9px;
  }
  &_right {
    padding-right: 20px;
  }
  &_left {
    &_large {
      padding-left: 40px;
    }
  }
  &_x {
    padding-left: 9px;
    padding-right: 9px;
    &_medium {
      padding-left: 24px;
      padding-right: 24px;
    }

    &_large {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  &_y {
    &_xs {
      padding-top: 11px;
      padding-bottom: 9px;
    }
    &_small {
      padding-top: 13px;
      padding-bottom: 13px;
    }
    &_medium {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    &_large {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &_xlarge {
      padding-bottom: 150px;
    }
  }
}


.btno {
  border-top: none;
}

.bbno {
  border-bottom: none;
}

.not_found_page_wrap {
  align-items: center;

  .not_found_page_top {
    padding: 50px;
    .sub_title1 {
      font-weight: 700;
    }

    .not_found_page_icon {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;

      &:before {
        background-image: url(../../../../assets/Icons/svg/Re.svg);
        background-repeat: no-repeat;
        width: 114px;
        height: 96px;
        content: '';
        display: block;
      }
    }

    .not_found_page_title {
      display: flex;
      justify-content: center;
    }
  }
}