.center_body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}


.loader_square {
  display: block;
  margin: auto;
  position: relative;
  width: 16px;
  height: 80px;
}

.loader_square > div {
  display: block;
  position: absolute;
  width: 50%;
  height: 50%;
}

.first {
  top: 0;
  left: 0;
  background-color: var(--red-500, #FF552D);
  animation: anm-sq-12-move 1s linear infinite;
}

.second {
  top: 0;
  left: 15px;
  background-color: var(--yellow-800, #F9A71A);
  animation: anm-sq-12-move 1s linear infinite;
  animation-delay: 0.3s; /* 1초 후 시작 */
}

.third {
  top: 0;
  left: 30px;
  background-color: var(--green-400, #00A886);
  animation: anm-sq-12-move 1s linear infinite;
  animation-delay: 0.6s; /* 2초 후 시작 */
}

.fourth {
  top: 0;
  left: 45px;
  background-color: var(--gray-900, #024383);
  animation: anm-sq-12-move 1s linear infinite;
  animation-delay: 0.9s; /* 3초 후 시작 */
}

@keyframes anm-sq-12-move {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}
