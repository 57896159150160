.list {
    padding: 24px 24px 24px 0;
    div {
        display: flex;
        align-items: center;
        gap: 55px;
        margin-bottom: 12px;
    }
    .btn {
        display: inline-block;
        border: none;
        &_add {
            cursor: pointer;
            border: 0.5px solid #e5e5e5;
            border-radius: 0;
            background: #f7f7f7;
            margin: 0 auto;
            width: 100%;
            height: 72px;
        }
    }
}
