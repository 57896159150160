.wrapper {
    padding: 0 18px 36px 18px;
    background: #f6f6f6;
}

.mapArea {
    position: relative;

    // margin-bottom: 60px;
    span {
        position: absolute;
        color: var(--gray-600);
    }
}

.mapWrap {
    position: relative;
    height: 722px;
    background-color: #ffffff;
}
.region {
    // 기준은 전체 지역정보의 경기도입니다.
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 113px;
    left: 248px;
    margin: 0;
    width: 62px;
    height: 62px;
    border: 3px solid var(--gray-900);
    border-radius: 100%;
    background-color: var(--gray-white);
    color: var(--red-800);
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 12.5px;
        height: 14px;
        background: url('/assets/Icons/svg/tail.svg') no-repeat top center/ contain;
        transform: rotate(310deg);
        transform-origin: 610% 100%;
    }
    &.incheon {
        &::before {
            transform: rotate(270deg);
            transform-origin: 410% 60%;
        }
        top: 125px;
        left: 117px;
    }
    &.gangwon {
        &::before {
            transform: rotate(-10deg);
            transform-origin: 2030% 330%;
        }
        top: 100px;
        left: inherit;
        right: 190px;
    }
    &.chungnam {
        top: 215px;
        left: 200px;
    }
    &.chungbug {
        &::before {
            transform: rotate(-10deg);
            transform-origin: 2030% 330%;
        }
        top: 200px;
        left: 318px;
    }
    &.kyeongbuk {
        &::before {
            transform: rotate(-10deg);
            transform-origin: 2030% 330%;
        }
        top: 238px;
        left: inherit;
        right: 143px;
    }
    &.jeonbuk {
        &::before {
            transform: rotate(270deg);
            transform-origin: 410% 60%;
        }
        top: inherit;
        bottom: 335px;
        left: 180px;
    }
    &.daegu {
        &::before {
            transform: rotate(270deg);
            transform-origin: 410% 60%;
        }
        top: inherit;
        bottom: 350px;
        left: inherit;
        right: 230px;
    }
    &.jeonnam {
        &::before {
            transform: rotate(270deg);
            transform-origin: 410% 60%;
        }
        top: inherit;
        bottom: 250px;
        left: 140px;
    }
    &.gwangju {
        &::before {
            transform: rotate(-10deg);
            transform-origin: 2030% 330%;
        }
        top: inherit;
        bottom: 265px;
        left: 248px;
    }
    &.gyeongnam {
        &::before {
            transform: rotate(80deg);
            transform-origin: 45% 20%;
        }
        top: inherit;
        bottom: 218px;
        left: inherit;
        right: 185px;
    }
    &.ulsan {
        &::before {
            transform: rotate(80deg);
            transform-origin: 45% 20%;
        }
        top: inherit;
        bottom: 235px;
        left: inherit;
        right: 98px;
    }
    &.jeju {
        top: inherit;
        bottom: 105px;
        left: 178px;
    }
}
.zone {
    .image_wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 515px;
    }
    .incheon {
        .inner_wrap {
            top: 55%;
            left: 60%;
        }
    }
    .gyeonggi {
        .inner_wrap {
            left: 60%;
        }
    }
    .chungbug {
        .inner_wrap {
            left: 40%;
        }
    }
}
.inner_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    span {
        position: relative;
        color: var(--gray-white);
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
    }
    .region {
        top: -28px;
        left: calc(100% + 20px);
        width: 120px;
        height: 120px;
        font-size: 32px;
        line-height: 40px;
        transform: translateY(-50%);

        &::before {
            width: 27px;
            height: 24px;
            transform: rotate(375deg);
            transform-origin: -1010% -60%;
        }
    }
}
.ongoing_items {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 0 28px;

    .item {
        padding: 16px 15px 27px;
        border: 1px solid var(--gray-300);
        border-radius: 4px;
        color: var(--gray-900);
        text-align: center;
    }
    i {
        display: inline-block;
        width: 96px;
        height: 65px;
        background-color: var(--gray-whit);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        text-indent: -9999rem;
    }
    .file {
        background-image: url('/assets/Icons/svg/file.svg');
    }
    .prize {
        background-image: url('/assets/Icons/svg/prize.svg');
    }
    .mortarboard {
        background-image: url('/assets/Icons/svg/mortarboard.svg');
    }
    .book {
        background-image: url('/assets/Icons/svg/book.svg');
    }
    span {
        display: block;
        position: relative;
        margin-top: 8px;
        color: var(--gray-900);
        font-size: 16px;
        line-height: 28px;
    }
    strong {
        display: block;
        font-size: 20px;
        line-height: 28px;
    }
}

[class='ant-image'] {
    // transition: opacity 0.1s;
}

.mb {
    &_sm {
        margin-bottom: 14px;
    }
    &_md {
        margin-bottom: 36px;
    }
    margin-bottom: 60px;
}

.title {
    color: var(--gray-900, #252525);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    word-break: keep-all;

    &_large {
        font-size: 32px;
        font-weight: 700;
    }
}

.chartArea {
    background: #f6f6f6;

    .bar {
        position: relative;
        margin-top: 90px;
        margin-bottom: 60px;
        background-color: var(--gray-white);
    }
}

[class='ant-modal-title'] {
    font-size: 32px !important;
}

[class='ant-progress-text'] {
    display: none !important;
}

.table {
    table {
        border-collapse: collapse; /* 셀 경계를 합칩니다. */
        width: 100%; /* 테이블 너비 설정 */
    }

    [class='ant-table-body'] {
        overflow: auto !important;
    }

    thead td {
        background: none !important;
    }

    thead th {
        background: var(--gray-white) !important;

        border-top: 2px solid var(--gray-900) !important; /* 위쪽 border 설정 */
        border-bottom: 1px solid var(--gray-900) !important; /* 아래쪽 border 설정 */
        color: var(--gray-900, #252525);

        /* Subtitle/T2 */
        text-align: center !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 175% */
        &:before {
            display: none !important;
        }
    }

    tbody tr {
        cursor: pointer;
    }

    tbody td {
        color: var(--gray-600, #7a7a7a);
        text-align: center;

        /* Body/B2 */
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 175% */
        button {
            display: flex;
            justify-content: center;
        }
    }

    tbody td {
        &:last-child {
            text-align: -webkit-center;
        }
    }
}
.text_red {
    color: #2d8dd2;
}
.text_blue {
    color: #ff6f4b;
}
.mapAreaYear {
    padding-top: 131px;
    background: #f6f6f6;
    position: relative;
    height: 1000px;

    .cardbox {
        display: flex;
        position: absolute;

        .inner {
            height: 116px;
            width: 313px;
            flex-shrink: 0;
            padding: 15px 20px;
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }

    .circleArea {
        position: relative;
        [class='ant-progress-inner'] {
            width: 66px !important;
            height: 66px !important;
        }
        .progressLabel {
            position: absolute;
            top: 50%;
            z-index: 10;
            font-family: Noto Sans KR;
            color: #252525;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: -1.5px;
            text-align: center;
            transform: translateY(-50%);
        }
        span {
            text-align: center;
            color: #ff6f4b;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0px;
        }
    }

    .left {
        left: 75px;
        .inner {
            background: #fff;
            border: 1px solid #024383;
            border-radius: 4px;
        }
    }
    .right {
        right: 100px;
        .inner {
            background: #fff;
            border: 1px solid #024383;
            border-radius: 4px;
        }
    }
    .arrow {
        margin-top: 53px;
    }
    .rightArrow {
        margin-top: 55px;
        padding-right: 0px;
    }
    .leftArrow {
        margin-top: 15px;
    }
    .arrowTop {
        margin-top: -105px;
        padding-right: 0px;
    }
    .arrowBtm {
        margin-top: -10px;
    }
}

.align {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.subTitle {
    margin: 0;
    background: #474747;
    border-radius: 4px 4px 0 0;
    color: #ffffff;
    font-size: 24px;
    padding: 16px 28px;
}

.center {
    display: flex;
    justify-content: center;
}

.humanGraph {
    position: relative;
    width: 120px;
    height: 16px;
    background-image: url('/assets/images/human-default.svg');
    background-repeat: repeat-x;
}

.humanFillBlue {
    position: absolute;
    max-width: 100%;
    height: 16px;
    background-image: url('/assets/images/human-fill-blue.svg');
    background-repeat: repeat-x;
}

.humanFillGreen {
    position: absolute;
    max-width: 100%;
    height: 16px;
    background-image: url('/assets/images/human-fill-green.svg');
    background-repeat: repeat-x;
}

.ant-progress-text {
    display: none;
}
