.ant_input {
    font-size: 16px;
    padding: 12px;

    &:disabled {
        border: none;
        background: #fff !important;
        color: #7a7a7a;
    }
    [class='ant-input-suffix'] {
        cursor: pointer;
    }

    [id='btn_close_icon'] {
        &:hover {
            path {
                fill: var(--gray-600);
            }
        }
    }
}

.width {
    &_xs {
        width: 75px;
    }
    &_sm {
        width: 179px;
    }
    &_md {
        width: 279px;
    }
    &_lg {
        width: 446px;
    }
    &_xl {
        width: 574px;
    }
    &_100 {
        width: 100%;
    }
}

/* styles.css */

.input-container:hover .input-suffix {
    display: inline-block;
}

.input_suffix {
    display: none;
}
