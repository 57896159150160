.badge {
  display: inline-flex;

  path {
    stroke: var(--gray-white, #fff);
  }

  button {
    margin-right: 8px;
  }
}

.title_wrap {
  .title {
    display: inline-flex;
    padding-top: 12px;
    max-width: 525px;
  }

  .subtitle {
    padding-left: 5px;
    font-size: 15px;
    color: #7a7a7a;
  }

  .attachment {
    padding-top: 15px;
    padding-left: 8px;
  }

  svg {
    display: flex;
    align-content: center;
  }
}

.more {
  position: absolute;
  top: 20px;
  right: 20px;
}

.item_wrap {
  list-style: none;
  padding: 0;

  ul {
    list-style: inside;
    color: var(--gray-400, #C2C2C2);
    padding: 0;
    margin: 0;
  }

  //ul {
  //  background-color: var(--gray-400, #C2C2C2);
  //  color: var(--gray-400, #C2C2C2);
  //  font-weight: bold;
  //  display: inline-block;
  //  width: 1em;
  //  margin-left: -1em;
  //}
  //li::before {
  //  content: "\2022";
  //  color: var(--gray-400, #C2C2C2);
  //  font-weight: bold;
  //  display: inline-block;
  //  width: 1em;
  //  margin-left: -1em;
  //}

  .item {
    position: relative;
  }
}

.edit_icon {
  background-image: url(../../../../assets/Icons/svg/edit.svg);
  background-repeat: no-repeat;
  width: 18px;
  height: 20px;
  content: "";
  display: block;
}

.delete_icon {
  background-image: url(../../../../assets/Icons/svg/trash.svg);
  background-repeat: no-repeat;
  width: 18px;
  height: 20px;
  content: "";
  display: block;
}


.subject {
  position: relative;
  padding-right: 10px;

  &:after {
    content: "";
    display: block;
    width: 1px;
    height: 16px;
    background: var(--gray-400, #E5E5E5);
    opacity: 0.48;
    position: absolute;
    right: 0;
    top: 50%; /* 수직 가운데 정렬을 위해 50%로 설정 */
    transform: translateY(-50%); /* 수직 정렬을 조정 */
  }
}

.content {
  padding-left: 10px;

  //display: inline-block;
  //overflow: hidden;
  //white-space: nowrap;
  //text-overflow: ellipsis;
  //max-width: 6em;
}