.card {
    &_vertical {
        height: 200px;
        width: 175px;
        padding: 30px 20px 20px 20px;
        text-align: center;
    }

    &_horizontal {
        background: #fbfbfb;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 380px;
        height: 144px;
        padding: 35px 40px;
        text-align: right;
    }

    &_key {
        display: block;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0px;
        color: #7a7a7a;
    }

    &_value {
        display: block;
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0px;
    }

    &_input {
        width: 88px;
        margin-left: 100px;
    }
}

.border_style {
    border: 1px solid #252525;
    border-radius: 4px;
}

.icon {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
