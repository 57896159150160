.badge {
  button {
    margin-right: 8px;
  }
}

.title_wrap {
  .title {
    display: inline-flex;
    padding-top: 12px;
    max-width: 525px;

    [class=sub_title1] {
      padding-right: 8px;
    }
  }

  .attachment {
    padding-top: 15px;
    padding-left: 8px;
  }

  svg {
    display: flex;
    align-content: center;
  }
}

.more {
  position: absolute;
  top: 20px;
  right: 20px;
}

.edit_icon {
  background-image: url(../../../../assets/Icons/svg/edit.svg);
  background-repeat: no-repeat;
  width: 18px;
  height: 20px;
  content: "";
  display: block;

  //&:hover::after {
  //  path {
  //    stroke: var(--gray-900, #252525);
  //    fill: var(--gray-900, #252525);
  //  }
  //}
  &:hover::after path {
    stroke: var(--gray-900, #252525);
    fill: var(--gray-900, #252525);
  }
}

.delete_icon {
  background-image: url(../../../../assets/Icons/svg/trash.svg);
  background-repeat: no-repeat;
  width: 18px;
  height: 20px;
  content: "";
  display: block;
}

.item_wrap {
  //padding-inline-start: 1.5em;
  list-style: none;

  ul {
    list-style: inside;
    color: var(--gray-400, #C2C2C2);
    padding: 0;
    margin: 0;
  }

  //li {
  //  display: inline-flex;
  //}

  //li::before {
  //  content: "\2022";
  //  color: var(--gray-400, #C2C2C2);
  //  font-weight: bold;
  //  display: inline-block;
  //  width: 1em;
  //  margin-left: -1em;
  //}

  .item {
    //position: relative;
  }
}

.red {
  color: var(--red-400, #FF6F4B);

  /* Body/B2 */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.subject {
  position: relative;
  padding-right: 10px;

  &:after {
    content: "";
    display: block;
    width: 1px;
    height: 16px;
    background: var(--gray-400, #E5E5E5);
    opacity: 0.48;
    position: absolute;
    right: 0;
    top: 50%; /* 수직 가운데 정렬을 위해 50%로 설정 */
    transform: translateY(-50%); /* 수직 정렬을 조정 */
  }
}

.content {
  padding-left: 10px;

  //display: inline-block;
  //overflow: hidden;
  //white-space: nowrap;
  //text-overflow: ellipsis;
  //max-width: 6em;
}