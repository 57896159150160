.btn {
  padding-right: 4px;

  &:hover {
    &.border_color {
      border-radius: 4px;

      button {
        border: 1px solid var(--gray-900, #252525) !important;
        font-weight: 400 !important;
        color: var(--gray-900, #252525) !important;

        path {
          fill: var(--gray-900, #252525) !important;
        }
      }
    }
  }

  &.active {
    &.border_color {
      border-radius: 4px;

      button {
        border: 1px solid var(--gray-900, #252525) !important;
        font-weight: 700;

        path {
          fill: var(--gray-900, #252525) !important;
        }
      }
    }
  }

  // TODO: 필터 active시 체크 아이콘 stroke 수정 필요
  &.active {
    path {
      stroke: var(--gray-white) !important;
    }

    .yellow,
    .green,
    .blue,
    .red,
    .darkgray {
      color: var(--gray-white) !important;

      path {
        stroke: var(--gray-white) !important;
      }
    }

    &.red {
      button {
        background-color: var(--red-700, #EB4824) !important;
        color: var(--gray-white, #fff) !important;
      }
    }

    .black,
    .border {
      color: var(--gray-900);
    }

    .black {
      path {
        fill: var(--gray-900);
      }
    }

    // 취소 버튼
    &.border {
      button {
        background: var(--gray-300, #E5E5E5);
      }

      .border {
        //background: var(--gray-300, #E5E5E5);
        color: var(--gray-white, #fff);
      }

      svg {
        stroke: var(--gray-white, #fff);
      }

      path,
      circle {
        stroke: var(--gray-white, #fff);
      }

      rect {
        fill: var(--gray-white, #fff);
      }
    }
  }

}

.yellow {
  &:hover {
    button {
      border: 1px solid var(--yellow-800) !important;
    }

    .yellow {
      color: var(--gray-900, #252525);
    }
  }
}

.red {
  &:hover {
    button {
      border: 1px solid var(--red-700, #EB4824) !important;
    }

    .red {
      //color: var(--red-700, #EB4824);
      color: var(--gray-900, #252525);
    }
  }
}

.green {
  &:hover {
    button {
      border: 1px solid var(--green-400, #00A886) !important;
    }

    .green {
      color: var(--gray-900, #252525);
    }
  }
}

.blue {
  &:hover {
    button {
      border: 1px solid var(--blue-500) !important;
    }

    .blue {
      color: var(--gray-900, #252525);
    }
  }
}

.darkgray {
  &:hover {
    button {
      border: 1px solid var(--gray-600) !important;
    }

    .darkgray {
      color: var(--gray-900, #252525);
    }
  }
}

.border {
  &:hover {
    button {
      border: 1px solid var(--gray-600) !important;
    }

    .border {
      color: var(--gray-900, #252525);
    }
  }

}