.container {
    display: flex;
    padding: 12px 0;
    height: 70px;

    :first-child {
        border-radius: 4px 0px 0px 4px;
    }

    :last-child {
        border-radius: 0px 4px 4px 0px;
    }

    &.disabled {
        pointer-events: none;
    }
}

.option {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #e5e5e5;
    color: #c2c2c2;
    background: #fbfbfb;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
        background-color: #f5f5f5;
    }
}

.selected {
    background: #ffffff;
    color: #000000;
    border: 1px solid #000000;
}

.no_value {
    padding-top: 10px;
    font-size: 16px;
}

.width {
    &_sm {
        width: 179px;
    }
    &_md {
        width: 294px;
    }
    &_lg {
        width: 446px;
    }
    &_xl {
        width: 544px;
    }
}
