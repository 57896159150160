.upload_icon {
  width: 100px;
  height: 100px;

  background-image: url("../../../assets/Icons/svg/upload.svg");
  background-repeat: no-repeat;
  background-position: 50%;
}


.upload_icon_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.added_file_area {
  border-radius: 4px;
  border: 1px solid var(--gray-300, #E5E5E5);
  background: var(--gray-white, #FFF);

  margin-top: 12px;
  padding: 18px 28px;

  display: flex;
  justify-content: space-between;
}

.delete_btn {
  background-image: url("../../../assets/Icons/svg/gray_trash.svg");
  background-repeat: no-repeat;

  width: 18px;
  height: 20px;
  display: block;
}