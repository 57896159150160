.ant-space {
  [id=previous_page_icon] {
    margin-left: 8px;
  }

  [id=next_page_icon] {
    margin-right: 8px;
  }

  [rel=nofollow] {
    border: 1px solid var(--gray-300, #E5E5E5);
    border-radius: 3px;
  }

  .ant-pagination-item-active [rel=nofollow] {
    border: none;
  }

  [class*="ant-pagination-item-active"] { // 이름이 포함된 경우
    [rel=nofollow] {
      color: var(--gray-900, #252525) !important;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      border: 1px solid var(--gray-900, #252525);
      border-radius: 3px;
    }
  }

  a {
    color: var(--gray-400, #C2C2C2) !important;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  [class=ant-pagination-options] {
    display: none;
  }
}
