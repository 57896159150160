
.container {
  display: flex;
  justify-content: center;
  position: relative;

  height: 55px;
  padding-top: 8px;
  align-items: center;
}

.register_btn {
  position: absolute;
  right: 0;

  display: flex;
  gap: 1rem;
}

.margin {
  &_b48 {
    margin-bottom: 48px;
  }

  &_r64 {
    margin-right: 64px;
  }
}