.value {
    font-size: 16px;
    color: #7a7a7a;
    word-break: keep-all;
}
.no_value {
    color: #7a7a7a;
    font-size: 16px;
}
.addon {
    color: #252525;
    font-size: 16px;
}
