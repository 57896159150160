.container {
  width: 1290px;
  margin-bottom: 48px;
}

.card {
  width: 638px;
  height: 232px;
  flex-shrink: 0;
  position: relative;

  border-radius: 4px;
  border: 1px solid var(--gray-300, #E5E5E5);
  background: var(--gray-white, #FFF);
  cursor: pointer;

  padding: 32px 40px;

  &:hover {
    border-radius: 4px;
    border: 1px solid var(--gray-900, #252525);
    background: var(--gray-white, #FFF);

    /* Pop */
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.05), 0px 12px 16px -4px rgba(16, 24, 40, 0.10);
  }
}