.editor-wrap {
    padding: 64px;

    // 에디터 높이값 수정
    .ck-editor__editable_inline {
        height: 450px !important;
    }

    .border {
        border: 1px solid #e5e5e5;
        border-bottom: none;
        border-radius: 4px 4px 0 0;
    }
    .border_btm {
        border-bottom: 1px solid #e5e5e5;
    }
}

.complete {
    padding: 64px;
    height: 687px;
    display: flex;
    justify-content: center;
    align-items: center;

    .txt {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 24px;
    }
}
