.contact_container {
    padding: 62px 64px 48px 64px;
    // .h6 {
    //     margin-bottom: 32px;
    // }
}
.view_title {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    .badge {
        margin-right: 10px;
        min-height: 36px;
        border-radius: 4px;
        padding: 0 12px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        font-size: 18px;
        font-weight: 700;
        line-height: 1;
        &.fill_green {
            background-color: var(--green-400, #00a886);
            color: var(--gray-white);
        }
        &.fill_error {
            background-color: var(--red-600, #f94f29);
            color: var(--gray-white);
        }
    }
    .contact_label {
        padding: 0 16px;
        &:last-child {
            padding-right: 0;
            position: relative;
            &::before {
                content: '';
                width: 1px;
                height: 12px;
                background-color: var(--gray-300, #e5e5e5);
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
        & + .blue {
            padding: 0 16px;
        }
        & + .gray {
            padding: 0 16px;
        }
    }
}

.ant-table-cell {
    position: relative;
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.red {
    color: var(--red-800, #dd4120) !important;
}
.write_outer {
    overflow: hidden;
    margin-bottom: 48px;
    .contact_card {
        border-radius: 4px 4px 0 0;
        margin-bottom: 0;
        border-bottom: 0;
    }
    .ant_textarea {
        border-radius: 0;
        padding: 24px;
        color: var(--gray-600, #7b7b7b);
        max-height: 452px;
    }
    .textarea-count {
        border: 1px solid #e5e5e5;
        border-top: 0;
        border-radius: 0 0 4px 4px;
        padding: 8px;
        span {
            color: var(--gray-600, #7b7b7b);
        }
    }
}
.admin_write {
    margin-top: 12px;
    margin-bottom: 48px;
    .ant_textarea {
        max-height: 350px;
        color: var(--gray-600, #7b7b7b);
    }
}
.contact_card {
    height: 100%;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    margin-bottom: 24px;
    & + .ant-form-item {
        margin-bottom: 0;
    }
}
.ant_textarea {
    padding: 24px;
}
.textarea-count {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.title_box {
    display: flex;
    .title_box_inner {
        width: 100%;
        padding: 36px;
        p {
            color: var(--gray-900);
            margin: 0;
        }
    }
    & + .title_box {
        border-top: 1px solid #e5e5e5;
    }
    .title_label {
        flex-shrink: 0;
        width: 107px;
        height: 70px;
        padding: 22px 0 22px 40px;
        font-size: 16px;
    }
}
.attachment_box {
    padding: 8px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    span {
        color: var(--gray-600, #7b7b7b);
    }
}
.contact_label {
    padding: 22px 40px;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    color: var(--gray-900);
    flex-shrink: 0;
    &.gray {
        color: var(--gray-600, #7b7b7b);
        padding-left: 12px;
        padding-right: 60px;
    }
    &.blue {
        color: var(--blue-900, #024383);
    }
}
.form_title {
    width: 100%;
    margin: 10px;
    .ant-form-item {
        margin-bottom: 0;
        width: 100%;
        .ant_input {
            font-size: 16px;
            padding: 9px 12px;
        }
    }
}
.form_select {
    margin: 10px;
    .ant-form-item {
        margin-bottom: 0;
        & [class^='selectui'] {
            width: 180px;
            height: 48px;
            padding: 0;
        }
    }
}
.form_email {
    margin: 10px;
    .ant-form-item {
        margin-bottom: 0;
    }
    .ant-form-item-control-input-content {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        .ant-input {
            height: 48px;
        }
    }
}

.gray_box {
    background-color: #fbfbfb;
    border-radius: 4px;
    padding: 60px 36px;
    margin-bottom: 48px;
}

/* align */
.text_right {
    margin-left: auto;
}

/* 240709 문의하기 작성 수정(file upload) */
.upload_wrap {
    width: 100%;
}
.upload_box {
    margin-top: 20px;
    width: 100%;
    //margin: 10px;
    // display: flex;
    // align-items: center;
    // gap: 16px;
    .gray {
        color: var(--gray-600, #7b7b7b);
    }
    .ant-btn {
        width: 125px;
        padding: 14px 16px !important;
        color: #024383;
        border: 1px solid #024383;
        & [class^='icons_'] {
            filter: brightness(0) saturate(100%) invert(29%) sepia(22%) saturate(1728%) hue-rotate(165deg)
                brightness(99%) contrast(94%);
        }
    }
}
.upload_list {
    .file-item {
        border-radius: 4px;
        border: 1px solid #e5e5e5;
        background: var(--gray-white, #fff);
        padding: 18px 28px;
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
    }
    // .file-item {
    //     width: 100%;
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     padding: 10px;
    //     padding-right: 36px;
    //     &:first-child {
    //         border-top: 1px solid #e5e5e5;
    //     }
    //     & + .file-item {
    //         padding-top: 0;
    //     }
    // }
}
