.txt_color {
    color: #252525;
}

.container {
    margin-bottom: 48px;
}

.btn_wrap {
    button {
        svg {
            position: static !important;
            top: auto !important;
            left: auto !important;
            transform: none !important;
        }
    }
}

.title {
    color: var(--gray-900, #252525);

    /* Header/H6 */
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;

    padding-bottom: 20px;

    &_large {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 20px;
    }
}

.section {
    padding: 64px 64px 0 64px;
}

.table {
    table {
        border-collapse: collapse;
        width: 100%;
    }

    [class='ant-table-body'] {
        overflow: auto !important;
    }

    thead {
        td {
            background: none !important;
        }

        th {
            background: var(--gray-white) !important;

            border-top: 2px solid var(--gray-900) !important;
            border-bottom: 1px solid var(--gray-900) !important;
            color: var(--gray-900, #252525);

            /* Subtitle/T2 */
            text-align: center !important;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
            &:before {
                display: none !important;
            }
        }
    }

    tbody {
        td {
            color: var(--gray-600, #7a7a7a);
            text-align: center;
            cursor: pointer;
            &:has(svg) {
                cursor: default;
            }
            &:last-child {
                text-align: -webkit-center;
            }

            /* Body/B2 */
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            button {
                display: flex;
                justify-content: center;
            }
        }
    }
}
.detail_icon {
    background-image: url(../../../assets/Icons/svg/new_window.svg);
    background-repeat: no-repeat;
    width: 18px;
    height: 20px;
    display: block;
    background-size: contain; // 이미지 크기를 요소 크기에 맞게 조정
}

.edit_icon {
    background-image: url(../../../assets/Icons/svg/edit.svg);
    background-repeat: no-repeat;
    width: 18px;
    height: 20px;
    //content: '';
    display: block;
}

.delete_icon {
    background-image: url(../../../assets/Icons/svg/trash.svg);
    background-repeat: no-repeat;
    width: 18px;
    height: 20px;
    content: '';
    display: block;
}

.edit_btn {
    background-image: url(../../../assets/Icons/svg/edit_dot.svg);
    background-repeat: no-repeat;
    width: 4px;
    height: 20px;
    display: block;
}

.red {
    color: var(--red-400, #ff6f4b);

    /* Body/B2 */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}
