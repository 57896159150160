.container {
  flex-shrink: 0;

  .card {
    width: 600px;
    margin: 24px 23px;

    .title_wrap {
      display: flex;
      justify-content: space-between;

      a {
        color: var(--gray-600, #7A7A7A);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; /* 128.571% */
        text-decoration-line: underline;

        &:hover {
          color: var(--gray-900, #252525);
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px; /* 128.571% */
          text-decoration-line: underline;
        }
      }

      .title {
        margin-bottom: 36px;
        display: flex;
      }
    }
  }
}
