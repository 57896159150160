.ant_select_selector {
  min-width: 150px;

  &::after {
    content: '';
    display: block;
    margin-top: 20px;
    margin-left: 2px;
    margin-right: 14px;
    width: 1px;
    height: 38px;
    background: var(--gray-white, #fff);
    opacity: 0.48;
    position: absolute;
    top: 0;
    right: -14px;
  }

  [class='ant-select-selection-item'] {
    color: var(--gray-600);

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
  }
}

.sm {
  width: 200px;
}

.md {
  width: 250px;
}

.lg {
  width: 300px;
}
