.banner {
  display: block;
  width: 403px;
  height: 260px;
  position: absolute;
  right: 0;
}


.program_img {
  background-image: url(https://d22kzwos811jtr.cloudfront.net/public/icons/programBanner.svg);
}

.tutor_img {
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/tutorBanner.svg) no-repeat center / 100% ;
}

.report_img {
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/reportBanner.svg) no-repeat;
}

.statistics_img {
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/statisticsBanner.svg) no-repeat;
}

.account_img {
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/accountBanner.svg) no-repeat;
}

.contact_img {
  background: url('../../assets/Icons/svg/Visual/contactBanner.svg') no-repeat;
}

.tools_img {
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/Menu03.svg) no-repeat;
}

.survey_img {
  background: url(https://dcucq2w0klhce.cloudfront.net/public/icons/Menu06.png) no-repeat;
  background-size: 100%;
}

.survey_success_img {
  background: url(https://dcucq2w0klhce.cloudfront.net/public/icons/surveySuccess.png) no-repeat;
  background-size: 100%;

  display: block;
  width: 135px;
  height: 172px;
}

.filter_icon {
  display: block;
  width: 60px;
  height: 60px;

  &.all {
    background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/All.svg) no-repeat;
  }

  &.folder_b {
    background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/FolderB.svg) no-repeat;
  }

  &.folder_g {
    background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/FolderG.svg) no-repeat;
  }

  &.folder_y {
    background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/FolderY.svg) no-repeat;
  }
}

.avatar {
  display: block;
  width: 18px;
  height: 22px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/avatar.svg) no-repeat;
}

.logout {
  display: block;
  width: 33px;
  height: 33px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/logout.svg) no-repeat;
}

.home {
  display: block;
  width: 48px;
  height: 48px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/Home.svg) no-repeat;
}

.footer_logo {
  display: block;
  width: 200px;
  height: 38px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/footer.svg) no-repeat;
}

.login_element {
  display: block;
  width: 296px;
  height: 372px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/login_element.svg) no-repeat;
}

.mail {
  display: block;
  width: 26px;
  height: 20px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/mail.svg) no-repeat;
}

.arrow_icon {
  display: block;
  width: 36px;
  height: 36px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/Close2.svg) no-repeat;
}

.attachment {
  display: block;
  width: 24px;
  height: 24px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/Attachment.svg) no-repeat;
}

.more {
  display: block;
  width: 4px;
  height: 20px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/more.svg) no-repeat;
}

// 필터

.going_icon {
  display: block;
  width: 24px;
  height: 24px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/onGoing.svg) no-repeat;
}

.finish_icon {
  display: block;
  width: 24px;
  height: 24px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/onFinish.svg) no-repeat;
}

.expect_icon {
  display: block;
  width: 24px;
  height: 24px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/onExpect.svg) no-repeat;
}

.cancel_icon {
  display: block;
  width: 24px;
  height: 24px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/onCancel.svg) no-repeat;
}

.short_period_icon {
  display: block;
  width: 24px;
  height: 24px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/shortPeriod.svg) no-repeat;
}

.middle_period_icon {
  display: block;
  width: 24px;
  height: 24px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/middlePeriod.svg) no-repeat;
}

.long_period_icon {
  display: block;
  width: 24px;
  height: 24px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/longPeriod.svg) no-repeat;
}

.contract_badge_icon {
  display: block;
  width: 24px;
  height: 24px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/contract.svg) no-repeat;
}

.expire_badge_icon {
  display: block;
  width: 18px;
  height: 24px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/expire.svg) no-repeat;
}

.right_arrow_48_icon {
  display: block;
  width: 20px;
  height: 32px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/rightArrow48.svg) no-repeat;
}


.left_arrow_48_icon {
  display: block;
  width: 20px;
  height: 32px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/leftArrow48.svg) no-repeat;
}


// Icon/24/Teacher_G
.normal_tutor_icon {
  display: block;
  width: 24px;
  height: 24px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/Teacher_H.svg) no-repeat;
}

// Icon/24/T_H/Off
.advanced_tutor_icon {
  display: block;
  width: 24px;
  height: 24px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/Teacher_G.svg) no-repeat;
}

.working_icon {
  display: block;
  width: 36px;
  height: 36px;
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/OnWorking.svg) no-repeat;
}