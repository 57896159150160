.label {
    padding: 24px;
    font-size: 16px;
    span {
        white-space: nowrap;
    }
}
.addon {
    color: #252525;
}

.asterisk {
    color: red;
}
