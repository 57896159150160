.ant-modal {
  [class='ant-modal-content'] {
    width: 418px;
    height: 100%;
    padding: 36px;
    margin: 0;
  }

  [class='ant-modal-title'] {
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  [class='ant-modal-body'] {
    overflow: hidden;
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    padding: 20px 0;
    color: #7a7a7a;
  }

  [class='ant-modal-footer'] {
    display: flex;
    justify-content: center;
  }
}

.ant_article {
  [class='ant-modal-content'] {
    width: 484px;
    //height: 385px;
    padding: 36px 30px;
    margin: 0;

  }

  [class='ant-modal-title'] {
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  [class='ant-modal-body'] {
    overflow: hidden;
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    padding: 0;
    color: #7a7a7a;
  }

  [class='ant-modal-footer'] {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
}


.input_section {
  .title {
    margin-bottom: 17px;
  }

  width: 100% !important;

  .ant_select_selector {
    width: 100% !important;
  }

  [class=ant-select] {
    width: 100% !important;
  }

  //[class=ant-col] {
  //  display: flex !important;
  //  align-items: center !important;
  //}

  [class=ant-divider-horizontal] {
    margin: 0 !important;
  }

  [class=ant-form-item] {
    margin: 0 !important;
  }

  [class=ant-select-selection-item] {
    text-align: left;
  }

  [class=ant-form-item-control-input-content] {
    margin-right: 26px !important;
  }

  [class=ant-form-item] {
    margin-bottom: 0 !important;
  }

  [class=ant-select-single] {
    height: 50px !important;
  }

  .ant_select_selector {
    min-height: 50px !important;
  }
}

.item {
  margin-bottom: 0;
  margin-top: 24px;

  button:first-child {
    margin-right: 8px;
  }
}


.red {
  color: var(--red-400, #FF6F4B);

  /* Body/B2 */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.none {
  display: none;
}

.label {
  padding: 18px 16px 19px 16px;
  text-align: left;

  span {
    color: var(--gray-900, #252525);

    /* Body/B2 */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
  }
}

.label_memo {
  padding: 9px 16px 56px 14px;
  text-align: left;

  span {
    color: var(--gray-900, #252525);

    /* Body/B2 */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
  }
}

.edit_disable {
  color: var(--gray-600, #7A7A7A);
  font-size: 16px;
  padding: 12px;
  text-align: left;
}

.bb {
  border-bottom: 1px solid var(--gray-300);
}

.search_renter {
  margin-bottom: 0 !important;
  [class=ant-select-selection-placeholder] {
    text-align: left !important;
    font-size: 16px !important;
  }
  [class=ant-form-item] {
    margin-bottom: 0 !important;
  }
}
