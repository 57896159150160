.item {
  padding: 0;
  cursor: pointer;
  width: 600px;
  height: 76px;
  margin-bottom: 8px;

  position: relative;

  border-radius: 4px;
  border: 1px solid var(--gray-300, #E5E5E5);
  background: var(--gray-white, #FFF);

  .sub_title1 {
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .arrow {
    opacity: 0;
  }

  &:hover {
    padding: 0;
    border-radius: 4px;
    border: 1px solid var(--gray-900, #252525);
    background: var(--gray-white, #FFF);

    /* Pop */
    box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.05), 0px 12px 16px -4px rgba(16, 24, 40, 0.10);

    .arrow {
      opacity: 1;
      position: absolute;
      right: 0;
      padding: 20px 14px;
    }
  }

  .content {
    height: 76px;
    display: flex;
    align-items: center;

    padding: 20px;

    [class=sub_title1] {
      width: 400px;
      font-weight: 400;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      [class=sub_title1] {
        font-weight: 700;
      }
    }

    .badge_wrap {
      width: 100px;
      display: flex;

      .badge {
        button {
          margin-right: 8px;

        }
      }
    }
  }
}