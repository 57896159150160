.ant-table-wrapper {
    margin-top: 30px;

    a {
        color: #000;
        :hover {
            color: #000;
            font-weight: 500;
        }
    }
}
