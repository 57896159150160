.container {
  background: var(--gray-50);
  height: 900px;
}

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: -webkit-fill-available;
}
