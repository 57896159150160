.container {
  padding: 36px 40px;
}

.count {
  color: var(--yellow-800, #F9A71A);
}

.icon {
  display: block;
  width: 60px;
  height: 60px;
}

.inventory {
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/All.svg) no-repeat;

}
.rental{
  background: url(https://d22kzwos811jtr.cloudfront.net/public/icons/FolderB.svg) no-repeat;
}

.rental_list_wrap {
  height: 750px;
  overflow-y: auto;

  /* WebKit 계열 스크롤바 스타일 */
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #024383;
    border-radius: 6px;
    border: 1px solid var(--blue-600, #024383);
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: darkblue;
  }
}

.rental_register {
  margin-top: 100px;
}

.height {
  &_100 {
    height: 100%;
  }
}
.margin {
  &_b45 {
    margin-bottom: 45px;
  }
  &_b12 {
    margin-bottom: 12px !important;
  }
}

.btn {
  display: flex;
  width: 60px;
  height: 60px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: 4px;
  border: 1px solid var(--gray-900, #252525) !important;
  background: var(--gray-white, #FFF);

  /* Pop */
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.05), 0px 12px 16px -4px rgba(16, 24, 40, 0.10);
}

.disabled {
  border: 1px solid var(--gray-300, #E5E5E5)  !important;
  box-shadow: none !important;
}

.not_found_page_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; // 부모 요소의 높이를 100%로 설정 (또는 적절한 높이 지정)

  .not_found_page_top {
    padding: 50px;
    .sub_title1 {
      font-weight: 700;
    }

    .not_found_page_icon {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;

      &:before {
        background-image: url(../../../assets/Icons/svg/Re.svg);
        background-repeat: no-repeat;
        width: 114px;
        height: 96px;
        content: '';
        display: block;
      }
    }

    .not_found_page_title {
      display: flex;
      justify-content: center;
    }
  }
}
